.auth-container {
  height: 101vh;
  position: relative;
  display: block;
}

.auth-container:after {
  background-size: cover;
  background: url("../../../../public/images/bg_auth_1.jpg") no-repeat no-repeat center;
  opacity: 0.9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  content: "";
}