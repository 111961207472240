.ngx-snack-bar {
  min-height: 56px;
  box-shadow: rgb(0 0 0 / 20%) 0 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-display: swap;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgb(255, 255, 255);
  background-color: rgb(50, 50, 50);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 0.25rem;
  -webkit-box-flex: 1;
  flex-grow: 1;
  opacity: 1;
  transform: none;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .snack-bar-action {
    position: absolute;
    right: 5px;
    top: 20%;
  }
}