.text-error {
  color: rgb(255, 0, 0);
}

div,
span,
p,
a,
ul,
li,
svg,
ol,
strong,
b,
button,
table,
input {
  font-family: 'Noto Sans KR', sans-serif !important;
  font-display: swap;
}

body {
  font-display: swap;
  .react-tel-input {
    z-index: 333;
    .flag-dropdown {
      .country-list {
        .country {
          width: 100%;
        }
      }
    }
  }

}
