@import 'react-date-range/dist/styles.css'; // main style file
@import 'react-checkbox-tree/lib/react-checkbox-tree.css';
body {
  margin: 0;
  overflow: hidden;
  height: 100vh;
  background-color: transparent;
  font-family: 'Noto Sans KR', sans-serif !important;
  font-display: swap;
  min-width: 1800px;
  overflow: auto;
}

.MuiSkeleton-root {
  background-color: lightgray !important;
}
